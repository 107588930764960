<script>
import { Container, Header, Main, Row, Col, Table, TableColumn, Button, Checkbox, Select, Option, Divider, RadioGroup, RadioButton, DatePicker, Message,Tooltip } from 'element-ui'
import { Mixin } from '@/mixins/util.js'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
export default {
  name: 'salesData',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-header': Header,
    'el-main': Main,
    'el-row': Row,
    'el-col': Col,
    'el-checkbox': Checkbox,
    'el-select': Select,
    'el-option': Option,
    'el-divider': Divider,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-date-picker': DatePicker,
    'el-tooltip': Tooltip,
  },
  data(){
    return{
      cols:[
        // {key:'sale_month',label:'計上月',width:100,align:'center',sortable:"custom"},
        {key:'shipping_date',label:'出荷日',width:100,align:'center',sortable:"custom"},
        {key:'record_form_source',label:'販売先ID',width:80,align:'center'},
        {key:'order_id',label:'受注NO.',align:'center'},
        {key:'shipping_target',label:'納品先',align:'center',sortable:"custom"},
        {key:'product_state_name',label:'類型',width:50,align:'center'},
        {key:'product_name',label:'商品名',width:120},
        {key:'product_code',label:'品番',width:120},
        {key:'product_quantity',label:'數量',width:120,align:'right',sortable:"custom"},
        {key:'sale_ramount',label:'流通高',width:120,align:'right',sortable:"custom"},
        {key:'cost_ramount',label:'売上',width:120,align:'right',sortable:"custom"},
        {key:'transfer_amount',label:'送金額',width:120,align:'right',sortable:"custom"},
        {key:'pay_date',label:'支払い月',width:140,align:'center',sortable:"custom"},
      ],
      record_form_table:{
        '001_000001_000_00000000':'LC',
        '001_000033_000_00000000':'MOMO',
        '001_000033_001_00000000':'MOMO廠配',
        '001_000033_002_00000000':'MO店+',
        '001_000035_000_00000000':'YHOO',
        '001_000036_000_00000000':'SHOPEE',
        '001_000038_000_00000000':'SHOPLINE',
        '001_000039_000_00000000':'SHOPIFY',
        '001_000041_000_00000000':'PCHOME',
        '001_000041_001_00000000':'PCHOME廠配',
        '001_000043_000_00000000':'ETMALL',
        '001_000045_000_00000000':'BOOKS',
        '001_000048_000_00000000':'FRIDAY',
      },
      product_state_table:{
        1:'出貨',
        2:'返品',
      },
      salesData:[],
      mall_options:[],
      retail_options:[],
      product_type:'1',
      height:'75vh',
      sales_type1:true,
      sales_type2:true,
      sales_type3:true,
      pick_date:null,
      pick_paydate:null,
      pick_shippingdate:null,
      pickerOptions: {
          shortcuts: [
            {
              text: '本月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() , 1);
                const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '前月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const end = new Date(now.getFullYear(), now.getMonth() - 1 + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '前前月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() - 2, 1);
                const end = new Date(now.getFullYear(), now.getMonth() - 2 + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去7日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去14日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去30日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去60日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去90日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去180日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去一年',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                picker.$emit('pick', [start, end]);
              }
            }
          ]
      },
      activeNames:[],
      pn_selected:'',
      pc_selected:'',
      curPage: 1,
      pageSize: 50,
      pagerCount: 11,
      showFilterArea:true,
      page_size_options:[50,100,150],
      stateList:[]
    }
  },
  computed: {
    excludeList(){
      return this.stateList.filter(item=>{
        return item.product_state===false
      })
      .map(d=>d.product_code)
    },
    sum_of_product_quantity(){
      const sum = this.union_data_filter_pnpc.reduce((acc,cur)=>{
        if(cur.currency==='NTD')
          acc.NTD = cur.product_state === 1 ? acc.NTD + cur.product_quantity :acc.NTD - cur.product_quantity
        else if(cur.currency==='JPY')
          acc.JPY = cur.product_state === 1 ? acc.JPY + cur.product_quantity :acc.JPY - cur.product_quantity
        return acc
      },{NTD:0,JPY:0})
      return sum
    },
    sum_of_sale_ramount(){
      const sum = this.union_data_filter_pnpc.reduce((acc,cur)=>{
        if(cur.currency==='NTD')
          acc.NTD = cur.product_state === 1 ? acc.NTD + cur.sale_ramount : acc.NTD - cur.sale_ramount
        else if(cur.currency==='JPY')
          acc.JPY = cur.product_state === 1 ? acc.JPY + cur.sale_ramount : acc.JPY - cur.sale_ramount
        return acc
      },{NTD:0,JPY:0})
      return sum
    },
    sum_of_cost_ramount(){
      const sum = this.union_data_filter_pnpc.reduce((acc,cur)=>{
        if(cur.currency==='NTD')
          acc.NTD = cur.product_state === 1 ? acc.NTD + cur.cost_ramount : acc.NTD - cur.cost_ramount
        else if(cur.currency==='JPY')
          acc.JPY = cur.product_state === 1 ? acc.JPY + cur.cost_ramount : acc.JPY - cur.cost_ramount
        return acc
      },{NTD:0,JPY:0})
      return sum
    },
    sum_of_transfer_amount(){
      const sum =  this.union_data_filter_pnpc.reduce((acc,cur)=>{
        if(cur.currency==='NTD')
          acc.NTD = cur.product_state === 1 ? acc.NTD + cur.transfer_amount : acc.NTD - cur.transfer_amount
        else if(cur.currency==='JPY')
          acc.JPY = cur.product_state === 1 ? acc.JPY + cur.transfer_amount : acc.JPY - cur.transfer_amount
        return acc
      },{NTD:0,JPY:0})
      return sum
    },
    ec_data(){
      if(this.salesData.length === 0) return []
      if(!this.sales_type1) return []
      return this.salesData.lc
        .map(d=>{
          return {
            source:d[0],
            shipping_date:d[1],
            record_form:d[2],
            shipping_target:d[3],
            product_state:d[4],
            product_name:this.Mosaic(d[6],sessionStorage.getItem('session_id')),
            product_code:this.Mosaic(d[5],sessionStorage.getItem('session_id')),
            product_quantity:d[7],
            sale_ramount:d[8],
            cost_ramount:d[9],
            transfer_amount:d[10],
            pay_date:d[11],
            order_id:this.Mosaic(d[12],sessionStorage.getItem('session_id')),
            currency:d[13] || 'NTD'
          }
        })
        .map(d=>{
          d.record_form_source = this.record_form_table[d.record_form]
          d.product_state_name = this.product_state_table[d.product_state]
          return d
        })
        .filter(d=>{
          return this.excludeList.includes(d.product_code) === false
        })
    },
    mall_data(){
      if(this.salesData.length === 0) return []
      if(!this.sales_type2) return []
      return this.salesData.mall
        .map(d=>{
          return {
            source:d[0],
            shipping_date:d[1],
            record_form:d[2],
            shipping_target:d[3],
            product_state:d[4],
            product_name:this.Mosaic(d[6],sessionStorage.getItem('session_id')),
            product_code:this.Mosaic(d[5],sessionStorage.getItem('session_id')),
            product_quantity:d[7],
            sale_ramount:d[8],
            cost_ramount:d[9],
            transfer_amount:d[10],
            pay_date:d[11],
            order_id:this.Mosaic(d[12],sessionStorage.getItem('session_id')),
            currency:d[13] || 'NTD'
          }
        })
        .map(d=>{
          d.record_form_source = this.record_form_table[d.record_form]
          d.product_state_name = this.product_state_table[d.product_state]
          return d
        })
        .filter(d=>{
          return this.excludeList.includes(d.product_code) === false
        })
    },
    retail_data(){
      if(this.salesData.length === 0) return []
      if(!this.sales_type3) return []
      return this.salesData.retail
        .map(d=>{
          const sale_date = d[1].split('-')
          const sale_month = `${sale_date[0]}-${sale_date[1]}`
          return {
            source:d[0],
            shipping_date:d[14],
            record_form:d[2],
            shipping_target:d[3],
            product_state:d[4],
            product_name:this.Mosaic(d[6],sessionStorage.getItem('session_id')),
            product_code:this.Mosaic(d[5],sessionStorage.getItem('session_id')),
            product_quantity:d[7],
            sale_ramount:d[8],
            cost_ramount:d[9],
            transfer_amount:d[10],
            pay_date:d[11],
            order_id:this.Mosaic(d[12].split('_')[0],sessionStorage.getItem('session_id')),
            currency:d[13] || 'NTD',
            sale_month,
          }
        })
        .map(d=>{
          d.record_form_source = d.record_form.split('_')[3]
          d.product_state_name = this.product_state_table[d.product_state]
          return d
        })
        .filter(d=>{
          return this.excludeList.includes(d.product_code) === false
        })
    },
    filter_mall_data(){
      const options = this.mall_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.mall_data.filter(d=>options.includes(d.record_form_source))
    },
    filter_retail_data(){
      const options = this.retail_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.retail_data.filter(d=>options.includes(d.record_form_source))
    },
    union_data(){
      if(this.salesData.length === 0) return []
      const union_data = [...this.ec_data,...this.filter_mall_data,...this.filter_retail_data]
      let arr = union_data
      if(this.pick_paydate !== null){
        arr = union_data.filter((d)=>{
          return new Date(d.pay_date) >= new Date(this.pick_paydate[0]) && new Date(d.pay_date) <= new Date(this.pick_paydate[1])
        })
      }
      if(this.pick_shippingdate === null) return arr
      return arr.filter(d=>{
        return new Date(d.shipping_date) >= new Date(this.pick_shippingdate[0]) && new Date(d.shipping_date) <= new Date(this.pick_shippingdate[1])
      })
    },
    union_data_filter_pnpc(){
      return this.union_data
      .filter(d=>{
        if(this.pn_selected.length === 0) return d
        return this.pn_selected.includes(d.product_name)
      })
      .filter(d=>{
        if(this.pc_selected.length === 0) return d
        return this.pc_selected.includes(d.product_code)
      })
    },
    union_data_with_pager(){
      return this.union_data_filter_pnpc
        .slice(
          this.pageSize * (this.curPage - 1),
          this.pageSize * this.curPage
        )
    },
    pn_options(){
      return this.union_data
        .filter(d=>d.product_name !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_name)){
            acc.push(cur.product_name)
          }
          return acc
        },[])
    },
    pc_options(){
      return this.union_data
        .filter(d=>d.product_code !== '')
        .reduce((acc,cur)=>{
          if(!acc.find(option=>option === cur.product_code)){
            acc.push(cur.product_code)
          }
          return acc
        },[])
    }
  },
  methods: {
    sortChange({ prop, order }){
      const comparer = {
        shipping_date:1,
        shipping_target:3,
        product_quantity:7,
        sale_ramount:8,
        cost_ramount:9,
        transfer_amount:10,
      }
      const col = comparer[prop]
      switch(order){
        case 'ascending':
          this.salesData['lc'].sort((a,b)=>{
              if(a[col] < b[col]) return -1
              if(a[col] > b[col]) return 1
              return 0
            })
            this.salesData['mall'].sort((a,b)=>{
              if(a[col] < b[col]) return -1
              if(a[col] > b[col]) return 1
              return 0
            })
            this.salesData['retail'].sort((a,b)=>{
              if(a[col] < b[col]) return -1
              if(a[col] > b[col]) return 1
              return 0
            })
          break
        case 'descending':
          this.salesData['lc'].sort((a,b)=>{
              if(a[col] > b[col]) return -1
              if(a[col] < b[col]) return 1
              return 0
            })
            this.salesData['mall'].sort((a,b)=>{
              if(a[col] > b[col]) return -1
              if(a[col] < b[col]) return 1
              return 0
            })
            this.salesData['retail'].sort((a,b)=>{
              if(a[col] > b[col]) return -1
              if(a[col] < b[col]) return 1
              return 0
            })
          break
        default :
          break
      }
    },
    async getSalesData(){
      if(this.pick_date === null) {
        Message.error('Please select date')
        return
      } 
      const sales_type = this.sales_type1 * 4 + this.sales_type2 * 2 + this.sales_type3 * 1
      const [begin_date,end_date] = this.pick_date
      
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      // const url = `http://192.168.1.231:8080/suppliers/salesInfo`
      const url = `https://www.bamb.asia/suppliers/salesInfo`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&
        supplier_id=${s_uid}&
        sales_type=${sales_type}&
        product_type=${this.product_type}&
        begin_date=${begin_date}&
        end_date=${end_date}&
        action_type=3`,
      };

      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            rep.records['lc'] = rep.records['lc'].sort((a,b)=>{
              if(a[1] > b[1]) return -1
              if(a[1] < b[1]) return 1
              return 0
            })
            rep.records['mall'] = rep.records['mall'].sort((a,b)=>{
              if(a[1] > b[1]) return -1
              if(a[1] < b[1]) return 1
              return 0
            })
            rep.records['retail'] = rep.records['retail'].sort((a,b)=>{
              if(a[1] > b[1]) return -1
              if(a[1] < b[1]) return 1
              return 0
            })
            this.salesData = rep.records
            this.mall_options = this.mall_data.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === this.record_form_table[cur.record_form])){
                acc.push({label:this.record_form_table[cur.record_form],checked:true})
              }
              return acc
            },[])
            this.retail_options = this.retail_data.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === cur.record_form_source)){
                acc.push({label:cur.record_form_source,checked:true})
              }
              return acc
            },[])
          }
        })
    },
    formatter(row, column){
      if(row){
        if(column.property === 'product_quantity' || column.property === 'sale_ramount' || column.property === 'cost_ramount' || column.property === 'transfer_amount'){
          if(row.product_state === 2){
            const d = -1 * row[column.property] + 0
            return d.toLocaleString()
          }
          return row[column.property].toLocaleString()
        }
        if(column.property === 'pay_date'){
          const date =  row[column.property].split('-')
          return `${date[0]}-${date[1]}`
        }
        return row[column.property]
      }
    },
    priceformatter(row,key){
      let price = row[key]
      if(row.product_state === 2){
        price = -1 * row[key] + 0
      }
      if(key === 'product_quantity') return price.toLocaleString()
      return row.currency==='JPY'? `¥${price.toLocaleString()}`:price.toLocaleString()
    },
    sumFn(property,currency){
      const  sum = this[`sum_of_${property}`]
      if(property==='product_quantity') return sum[currency].toLocaleString()
      return currency ==='JPY'? `¥${sum[currency].toLocaleString()}`:sum[currency].toLocaleString()
    },
    exportExcelHandler(){
      if(this.union_data_filter_pnpc.length===0) return 
      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet()
      worksheet.columns = this.cols.map(col=>{
        return {header: col.label, key: col.key, width: 15}
      })
      worksheet.addRows(this.union_data_filter_pnpc)

      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `売上集計.xlsx`)
      })
    },
    getSetState(args) {
      if(this.stateList.length ===0)
        this.stateList = args //init for first time
      else{
        //modify single set
        const index = this.stateList.findIndex(item=>item.product_code === args.product_code)
        this.$set(this.stateList,index,args)
      }
    }
  },
  mounted(){
    // this.getSalesData()
    this.$eventBus.$on('getSetState', this.getSetState, this)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
